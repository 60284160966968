import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useInterval, useMount } from 'react-use'
import moment from 'moment'
import ReactPlayer from 'react-player'

import { Layout } from '../components'
import { container, padding, button } from '../styles/global'
import { media, useBreakpoint, isClient } from '../styles/utils'
import { formatDate, msToTime } from '../utils'

import { playtimes } from '../data/times'

const apiURL = 'https://worldtimeapi.org/api/timezone/Australia/Brisbane'
//const audioURL = 'https://brisbanefestival-sunsuper-nightsky.s3-ap-southeast-2.amazonaws.com/NIGHT_SKY_SOUNDTRACK.mp3'
//const audioURL = 'https://pda-clients.s3-ap-southeast-2.amazonaws.com/coen-beep.mp3'
const audioURL = 'https://pda-clients.s3-ap-southeast-2.amazonaws.com/nightsky-10secs.mp3'


const Test = (props) => {

    const audioRef = useRef(false)
    let latency = 0
    
    const [active, setActive] = useState(false)
    const [launched, setLaunched] = useState(false)
    const [launchedText, setLaunchedText] = useState('Launch')   
    const [eventFinished, setEventFinished] = useState(false)

    const [muted, setMuted] = useState(false)
    const [playingAudio, setPlayingAudio] = useState(false) 
    const [audioFileLoaded, setAudioFileLoaded] = useState(false)
    const [audioReady, setAudioReady] = useState(false)
    const [audioLoaded, setAudioLoaded] = useState(0) 
    const [audioProgress, setAudioProgress] = useState(0) 
    const [audioDuration, setAudioDuration] = useState(0) 
    const [audioEnded, setAudioEnded] = useState(false) 
    
    const [startTime, setStartTime] = useState(0) 
    const [startLatency, setStartLatency] = useState(0) 
    const [playerLatencyStart, setPlayerLatencyStart] = useState(0) 
    const [playerLatency, setPlayerLatency] = useState(0) 
    const [testingLatency, setTestingLatency] = useState(false) 
    const [testedLatencyBeforeNextPlay, setTestedLatencyBeforeNextPlay] = useState(false) 
    
    
    const [timestamps, setTimestamps] = useState(null)
    const [offset, setOffset] = useState(0)
    const [updatedTime, setUpdatedTime] = useState(0)
    //const [inputTime, setInputTime] = useState(moment().add(1,'hour').toDate());  //set to future initially
    const [inputTime, setInputTime] = useState(playtimes[0]);


    useEffect(() => {

        // need to call api twice to factor in initial connection start times
        fetch(apiURL).then( () => {
            getAPITime()
        })  

    }, []);


    


    // loop engine
    useInterval(() => {

        if(audioFileLoaded && !eventFinished){
            // Check if there are no more playtimes 
            if(Date.now() > (playtimes[playtimes.length-1].getTime() + audioDuration) ){
                setEventFinished(true)
                console.log('eventFinished');
            }
        }
       
        // start measuring player latency
        if(!active && audioFileLoaded && playingAudio && playerLatencyStart<1){
            setPlayerLatencyStart(performance.now())
        }

        // measure Player latency (launch button)
        if(!active && audioFileLoaded && audioProgress>0 && playerLatency<1){

            latency = performance.now() - playerLatencyStart
            console.log('latency',latency);
            // fix latency to 500ms if can't measure it properly
            if(latency>500) latency = 500
            setPlayerLatency(latency.toFixed(4))
            setTestedLatencyBeforeNextPlay(true)
        }


        // Open app if audio ready to play and latency measured
        if(!launched && !active && audioProgress>0 && playerLatency>0){

            setPlayingAudio(false)
            setMuted(false) 
            audioSeek(0)
            setLaunched(true)            
        }

        if(launched && !eventFinished){

            // set offset device time
            const offsetCurrentTime = (Date.now()+offset)
            const t = formatDate(offsetCurrentTime)
            setUpdatedTime(t)


            //measure player latency again (if !active and 10 secs from next playtime)
            if(!active && !testingLatency && !testedLatencyBeforeNextPlay && (offsetCurrentTime > (inputTime - 10000)) ){

                console.log('measuring player latency again 10 secs out from next show');
                setPlayerLatency(0)
                setPlayerLatencyStart(performance.now())
                setTestingLatency(true)
                setMuted(true)
                setPlayingAudio(true)
                audioSeek(0)
            }

            // testing latency in between shows
            if(!active && setTestingLatency && audioProgress>0 && playerLatency<1){
                latency = performance.now() - playerLatencyStart
                console.log('latency',latency);
                // fix latency to 500ms if can't measure it properly
                if(latency>500) latency = 500
                setPlayerLatency(latency.toFixed(4))
                setPlayerLatencyStart(0)
                setTestingLatency(false)
                setTestedLatencyBeforeNextPlay(true)
                setPlayingAudio(false)
                
            }


             // measure Player latency at play time
            if(active && audioProgress>0 && startLatency<1){
                setStartLatency((performance.now() - startTime).toFixed(4))
            }

             // PLAY AUDIO
            // - play audio if Input time is less than device time 
            // - factor in latency from player
            // - factor in offset from server 
    
            if(!active && !playingAudio && !eventFinished && ((inputTime-playerLatency) <= offsetCurrentTime)  ){ 

                // Seek Time - if already inside show time, set playhead position in seconds
                let seekTime = 0
                if(offsetCurrentTime > inputTime){
                    seekTime = (offsetCurrentTime - inputTime ) / 1000.
                }
                console.log('offsetCurrentTime',formatDate(offsetCurrentTime));
                console.log('inputTime',formatDate(inputTime));
                console.log('seekTime',seekTime);
    
                setMuted(false)
                setPlayingAudio(true) 
                audioSeek(seekTime)
                setActive(true)
                setStartTime(performance.now())
            }
        }
 
    },50)


    
    const onLaunchButton = () => {

        setLaunchedText('Please Wait')
        setPlayingAudio(true)
        setMuted(true) 
    }

    const onTimeSelectorChange = (val) => {
        setInputTime(val)
        setPlayingAudio(false)
        audioSeek(0)
    }

    const audioSeek = (sec) => {
        audioRef.current.seekTo(sec)
    }

    const onAudioReady = () => {
        //console.log('onAudioReady');
        setAudioReady(true)
    }

    const onAudioDuration = (val) => {
        //console.log('onAudioDuration',val);
        setAudioDuration(val*1000)
    }

    const onAudioProgress = (val) => {
        //console.log('onAudioProgress',val);
        setAudioLoaded(val.loaded)
        setAudioProgress(val.played)
    }
    
    const onAudioEnded = () => {
        console.log('onAudioEnded');

        // get next start time in playtimes
        const timeNow = Date.now()
        for (let item of playtimes){
            if( timeNow < (item.getTime() + audioDuration) ){
                const playtime = item.getTime()
                setInputTime(playtime)          
                break;
            }    
        }

        // Check if there are no more playtimes 
        if(timeNow > (playtimes[playtimes.length-1].getTime() + audioDuration) ){
            setEventFinished(true)
            console.log('eventFinished');
        }

        setAudioEnded(true)
        setActive(false)
        setPlayingAudio(false)
        setTestedLatencyBeforeNextPlay(false)
        setStartLatency(0)
    }

    const getAPITime = () => {
  
        const requestTime = performance.now()

        fetch(apiURL)
            .then(response => {
                return response.json()
            })  
            .then(data => {
                //console.log('data',data);
                processTime(requestTime,data) 
            })
    }

    const processTime = (requestTime,data) => {

        let td = {}

        td.now = Date.now()
        td.requestTime = requestTime
        td.resultTime = performance.now()     
        td.ttfb = (td.resultTime - td.requestTime)
        td.serverTime = new Date(data.utc_datetime).getTime()  //worldtimeapi    
        td.normalisedServerTime = td.serverTime +(td.ttfb/2)
        td.offset = parseFloat((td.normalisedServerTime - td.now).toFixed(2))

        setTimestamps(td)
        setOffset(td.offset)
    }


    const displayPlayTimes = () => {

        if(!playtimes) return

        const items = playtimes.map((item, i) => {
            return (
                <Item key={i}>{formatDate(item)}</Item>
            )
        })

        return (
            <Playtimes>
                <Item>Playtimes</Item>
                {items}
            </Playtimes>
        )

    }

    const displayTime = () => {

        if(!timestamps) return

        let countdown = inputTime - (Date.now()+offset)

        const data = [   
            
            { 
                title: 'Event Status',
                val: eventFinished ? 'Finished' : 'Active',
                color: 'black'
            }, 
            { 
                title: 'time entered',
                val: formatDate(inputTime),
                color: 'black'
            },     
            { 
                title: 'time countdown',
                val: countdown > 0 ? msToTime(countdown) : 0,
                color: 'black'
            },
            { 
                title: 'progress %',
                val: (audioProgress*100).toFixed(2),
                color: 'black'
            },
            { 
                title: 'actual Latency ms',
                val: startLatency,
                color: 'black'
            },
            { 
                title: 'measured Latency ms',
                val: testingLatency ? 0 : playerLatency,
                color: 'black'
            },
            { 
                title: 'offset device time',
                val: updatedTime,
                color: 'black'
            },
            { 
                title: 'status',
                val: playingAudio ? 'playing' : 'not playing',
                color: 'black'
            },
            { 
                title: 'audio loaded %',
                val: (audioLoaded*100).toFixed(2),
                color: 'black'
            },  
            
        ]

        const items = data.map((item, i) => {
            return (
                <Item key={i}>
                    <Subheading color={item.color}>{item.title}</Subheading>
                    <Text color={item.color}>{item.val}</Text>
                </Item>
            )
        })

        return (
            <Timestamps>{items}</Timestamps>
        )
    }


	return (
		<Layout
            // meta={data && data.seo}
        >
                <Wrapper>

                    <Container>

                        <Heading>Sunsuper Night Sky</Heading>

                        { !launched && (
                            <>
                                <LaunchButton onClick={() => onLaunchButton()}>{launchedText}</LaunchButton>
                                <Text>{`Audio Loaded: ${audioLoaded.toFixed(2)}`}</Text>
                                <Text>{`Audio Progress: ${(audioProgress*100).toFixed(2)}`}</Text>
                                <Text>{`Audio Ready: ${audioReady}`}</Text>  
                                {/* <Text>{`Audio Duration: ${audioDuration}`}</Text>      */}
                                <Text>{`player Latency: ${playerLatency}`}</Text>         
                            </>
                        )}


                        { launched && (
                            <>

                                <MuteButton 
                                    onClick={() => setMuted(!muted)}
                                >
                                    {muted ? 'Muted' : 'Mute'}
                                </MuteButton>
                            </>
                        )}  

                        <Audio 
                            ref={audioRef}
                            url={audioURL}
                            controls={true}
                            muted={muted} //
                            playing={playingAudio} //
                            loop={false}
                            volume={0.8}
                            // width={launched ? '90%' : '0px'}
                            // height={launched ? '50px' : '0px'}
                            width={'90%'}
                            height={'50px'}
                            progressInterval={100}
                            
                            onReady={ onAudioReady }
                            onEnded={ onAudioEnded }                  
                            onProgress={(e) => onAudioProgress(e)}

                            onPlay={()=>console.log('onPlay')}
                            onDuration={(e) => onAudioDuration(e)}
                            onBuffer={(e)=>console.log('onBuffer',e)}
                            onBufferEnd={()=>console.log('onBufferEnd')}
                            onSeek={(e)=>console.log('onSeek', e)}

                            config={{
                                file: {
                                    attributes: {
                                        preload: "auto",
                                        onCanPlayThrough: () => {
                                            setAudioFileLoaded(true)
                                            console.log('onCanPlayThrough')
                                        }
                                    },
                                },
                            }}
                            
                        />

                        
                        {/* { launched && displayPlayTimes() } */}
                
                        { launched && displayTime() }

                        
 

                    </Container>

               </Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div`
    color: ${props => props.color}; 
`
const Text = styled.div`
    color: ${props => props.color}; 
`
const Item = styled.div``




// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
    width: 100%;
`
const Container = styled.div`
	${container}
    ${padding}
    align-items: center;
    flex-direction: column;
    width: 500px; 

    ${media.phone`
        width: 100%;
	`}

    ${Heading}{
        margin: 50px 0;
        /* align-self: flex-start; */
    }

`

const LaunchButton = styled.div`
    ${button};
    border: 1px solid black;
    margin-bottom: 30px;
`

const MuteButton = styled.div`
    ${button};
    border: 1px solid black;
    margin-top: 40px;
`



const Timestamps = styled.div`
    width: 100%;
    margin-top: 40px;
    font-size: 20px;
    line-height: 1.5em;
    display: flex;
    flex-direction: column;

    ${Item}{
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        ${Subheading}{
            width: 50%;
            color: ${props => props.color};
            padding-right: 20px;
            text-align: right;
        }
        ${Text}{
            width: 50%;
            padding-left: 20px;
        }
    }
`

const Playtimes  = styled.div`
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${Item}{
        font-size: 15px;
        line-height: 1.5em;
    }
`

const Audio = styled(ReactPlayer)`
    /* visibility: hidden; */
    /* visibility: ${props => props.visibility}; */
    margin-top: 40px;
`
const Silence = styled.iframe`
    display: none;
`

export default Test